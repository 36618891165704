<template>
  <div id="stageline-overview">
    <div
      v-if="loaded && currentStage"
      class="stageline"
      :class="!nextIncompleteStages.length ? 'single-stage' : ''"
    >
      <div
        v-if="!allStagesCompleted"
        class="d-flex flex-column"
      >
        <div class="current-stage">
          <h3
            v-if="!isCtaPeriod"
            class="title text-center"
          >
            Current Stage
          </h3>

          <div class="stage-with-steps">
            <div
              v-if="ctaSlideImage?.image_urls.length"
              class="cta-image"
            >
              <img
                :src="ctaSlideImage.image_urls[0]"
                :alt="ctaSlideImage.image_alt_text"
              >
            </div>

            <stageline-navigation-icon
              v-else
              :type="currentStage.icon_name"
              :icon-status="iconStatus"
              :width="75"
              :height="75"
            />

            <div class="steps-list">
              <div class="sub-title">
                {{ isCtaPeriod ? 'Business Needing Review' : currentStage.title }}
              </div>
              <template v-if="isCtaPeriod">
                <div class="cta-title">
                  <h2>
                    Corporate Transparency Act
                  </h2>
                </div>
                <div v-html="ctaSlideDescription" />
              </template>
              <div
                v-for="step in stepsList"
                v-else
                :key="step.id"
                class="step"
                :class="{
                  'active': stepIsCurrent(step),
                  'complete': stepIsComplete(step) && !stepIsCurrent(step),
                }"
              >
                <div class="indicator">
                  <step-complete-icon v-if="stepIsComplete(step) && !stepIsCurrent(step)" />
                  <step-current-icon v-else-if="stepIsCurrent(step)" />
                </div>
                {{ step.title }}
              </div>
            </div>
          </div>
        </div>

        <div class="mt-auto align-items-end stageline-button-container">
          <b-button
            class="next-button stageline-button"
            variant="primary"
            aria-label="Continue button"
            @click="navigateToStageline()"
          >
            Continue
            <fa-icon icon="chevron-right" class="ml-1" />
          </b-button>
        </div>
      </div>

      <stageline-end-page v-else :is-slide="false" />

      <template v-if="!!nextIncompleteStages.length">
        <div class="divider" />
        <div class="d-flex flex-column">
          <div class="other-stages">
            <h3 class="title">
              Other Stages of Interest
            </h3>

            <div
              v-for="stage in nextIncompleteStages"
              :key="stage.id"
              class="stage"
            >
              <stageline-navigation-icon
                :type="stage.icon_name"
                :icon-status="iconStatus"
                :width="25"
                :height="25"
                class="stage-icon"
              />
              <a
                class="stage-name"
                href="#"
                :aria-label="stage.title + ' link'"
                @click.prevent="navigateToStageline(stage)"
              >
                {{ stage.title }}
              </a>
            </div>
          </div>

          <div class="mt-auto align-items-end stageline-button-container">
            <b-button
              class="next-button stageline-button more-stages-button"
              variant="outline-primary"
              aria-label="See more button"
              @click="navigateToDecisionTree"
            >
              See More
              <fa-icon icon="chevron-right" class="more-stages-chevron" />
            </b-button>
          </div>
        </div>
      </template>
    </div>
    <div v-else-if="loaded && !currentStage">
      <b-button
        class="next-button"
        variant="primary"
        aria-label="Start Stageline button"
        @click="navigateToStageline()"
      >
        Start Stageline
      </b-button>
    </div>
    <ct-centered-spinner v-else>
      Loading Stages...
    </ct-centered-spinner>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as DOMPurify from 'dompurify'
import { setStagelineJurisdiction } from '@/components/StagelineV2/helper.js'

export default {
  name: 'StagelineOverview',
  components: {
    CtCenteredSpinner:       () => import('@/components/shared/CtCenteredSpinner.vue'),
    StagelineNavigationIcon: () => import('@/components/StagelineV2/shared/StagelineNavigationIcon.vue'),
    StepCurrentIcon:         () => import('@images/ui/step-current.svg'),
    StepCompleteIcon:        () => import('@images/ui/step-complete.svg'),
    StagelineEndPage:        () => import('@/pages/StagelineV2/StagelineEndPage.vue'),
  },
  data() {
    return {
      loaded: false,
      iconStatus: 'icon-todo',
      slideId: '',
    }
  },
  computed: {
    ...mapGetters('stageline', [
      'currentPeriodName',
      'periodDeepLoaded',
      'firstIncompleteSlideId',
      'currentPeriod',
      'currentStageSlideList',
      'currentStage',
      'currentStep',
      'currentStepIndex',
      'stepIsLast',
      'stepIsFirst',
      'getStepByIndex',
      'company',
      'getSlideByIdOrName',
      'lastVisitedStage',
      'firstIncompleteSlideId',
      'isCtaPeriod',
      'currentSlide',
    ]),
    stepsList() {
      if (this.stepIsFirst) {
        return [
          this.currentStep,
          this.getStepByIndex(1),
          this.getStepByIndex(2),
        ].filter(s => s)
      } else if (this.stepIsLast) {
        return [
          this.getStepByIndex(this.currentStepIndex - 2),
          this.getStepByIndex(this.currentStepIndex - 1),
          this.currentStep,
        ].filter(s => s)
      } else {
        return [
          this.getStepByIndex(this.currentStepIndex - 1),
          this.currentStep,
          this.getStepByIndex(this.currentStepIndex + 1),
        ].filter(s => s)
      }
    },
    steps() {
      return this.currentStage?.steps.filter(steps => steps.slides?.length)
    },
    stages() {
      return this.currentPeriod?.stages
    },
    allStagesCompleted() {
      return this.stages.every(stage => stage.completed_at)
    },
    ctaSlideDescription() {
      if (!this.isCtaPeriod) return
      return DOMPurify.sanitize(this.currentStageSlideList.find(s => s.name === 'corporate_transparency_act__guide__company_select').description, {
        ALLOWED_TAGS: ['a', 'strong', 'em', 'p', 'br', 'div', 'span', 'li'],
        ALLOWED_ATTR: ['href', 'target', 'rel'],
      })
    },
    ctaSlideImage() {
      if (!this.isCtaPeriod) return
      return this.currentStageSlideList.find(s => s.name === 'corporate_transparency_act__guide__info')
    },
    nextIncompleteStages() {
      const incompleteStages = this.stages.filter(stage => !stage.completed_at)
      const index = incompleteStages.indexOf(this.currentStage)

      return [
        ...incompleteStages.slice(index + 1),
        ...incompleteStages.slice(0, index),
      ].slice(0, 3)
    },
  },
  async mounted() {
    await this.initializeStageline()
    if (!this.periodDeepLoaded(this.currentPeriodName)) await this.loadPeriod(this.currentPeriodName)
    setStagelineJurisdiction()

    this.slideId = this.$router.currentRoute.params.slideId

    if (!this.slideId) {
      let slide = this.getSlideByIdOrName(this.company.stageline?.last_visited_slide?.slide_id)
      const slideStageCompleted = this.stages.find(stage => stage.name === slide?.step?.stage?.name)?.completed_at != null

      if (!slide || slideStageCompleted) {
        this.slideId = this.firstIncompleteSlideId({ type: 'stage', typeId: this.lastVisitedStage.id })
        slide = this.getSlideByIdOrName(this.slideId)
      }
      this.setCurrentSlide({ slide, updateLastVisited: false })
    } else {
      this.goToSlide(this.slideId)
    }

    this.completeStagesStepsAndSlides()
    this.loaded = true
  },
  methods: {
    ...mapActions('stageline', [
      'initializeStageline',
      'loadPeriod',
      'setCurrentSlide',
      'completeStagesStepsAndSlides',
      'setJurisdiction',
      'goToSlide',
    ]),
    stepIsComplete(step) {
      return this.steps.find(s => s.id === step.id).completed_at !== null
    },
    stepIsCurrent(step) {
      return step.id === this.currentStep.id
    },
    async navigateToStageline(stage = null) {
      const slideId = stage
        ? this.firstIncompleteSlideId({ type: 'stage', typeId: stage.id })
        : this.slideId || this.currentSlide?.id

      await this.$router.push({ name: 'stageline-v2-start', params: {
          companyId: this.company.id,
          slideId: slideId,
        },
      })
    },
    async navigateToDecisionTree() {
      await this.$router.push({ name: 'stageline-v2-decision-tree', params: { companyId: this.company.id } })
    },
  },
}
</script>

<style scoped lang="scss">
#stageline-overview {
  .stageline {
    padding: 2em;
    width: 100%;
    border-radius: .2em;
    border: 1px solid $ct-ui-color-20;
    background-color: #F7F7FB;
    display: flex;
    flex-flow: row nowrap;
  }

  .single-stage {
    padding: 2em 6em;

    .cta-title {
      margin: 2em 0 1em;

      h2 {
        margin: 0;
      }
    }

    .cta-image{
      justify-self: center;
      align-self: center;
      margin-right: 2em;

      img {
        height: 15.625em;
        width: 15.625em;
      }
    }
  }

  .flex-column {
    width: 100%;
  }

  .more-stages-button {
    background-color: white;
  }

  .more-stages-chevron {
    color: #2FB2AE !important;
  }

  .current-stage, .other-stages {
    margin: 0 auto;
    display: flex;
    flex-flow: column nowrap;
    width: 80%;
  }

  .stage-with-steps {
    display: flex;
    flex-flow: row nowrap;
    gap: 1em;
  }

  .divider {
    background-color: $ct-ui-color-22;
    width: 0.125em;
    margin: 0 2em;
  }

  .title {
    font-weight: 800;
    margin-bottom: 1em;
  }

  .sub-title {
    justify-content: flex-start;
    font-size: 1.2em;
    font-weight: 800;
    text-align: left;
    margin-bottom: 1em;
  }

  .stage {
    width: 100%;
    position: static;
    margin-bottom: 0;
    padding-bottom: 1em;

    display: flex;
    flex-flow: row nowrap;

    justify-content: flex-start;
    align-items: center;

    .stage-icon {
      margin-right: 0.5em;
    }
  }

  .stage-name {
    color: $ct-ui-color-0;
  }

  .step {
    width: 100%;
    position: static;
    margin-bottom: 0;
    padding-bottom: 0.5em;
    color: $ct-ui-font-color-secondary;

    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;

    .indicator {
      background-color: $ct-ui-stepline-indicator-color-light;
      border-radius: 50%;
      border: $ct-ui-stageline-graphic-linewidth solid $ct-ui-stepline-indicator-color;
      width: 1.125em;
      height: 1.125em;
      display: flex;
      margin-right: 0.5em;
      margin-top: 0.2em;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &.active {
      font-weight: 900;
      color: black;
      .indicator {
        background: $ct-ui-stepline-indicator-color-light;
        svg {
          overflow: visible;
          height: 1.375em !important;
          path {
            fill: $ct-ui-stepline-indicator-color !important;
            stroke-width: 6 !important;
          }
        }
      }
    }
    &.complete {
      .indicator {
        background: $ct-ui-stepline-indicator-color;
        svg {
          overflow: visible;
          height: 1.625em !important;
          path {
            stroke-width: 6 !important;
          }
        }
      }
    }
  }

  .stageline-button {
    float: right;
    margin-top: 1em;
    border-radius: $stageline-primary-border-radius !important;
  }
}

@media (max-width: 767px) {
  #stageline-overview {
    .single-stage {
      padding: 2em;
      justify-content: center;
      text-align: center;

      .stage-with-steps {
        justify-content: center;
      }

      .cta-image {
        margin-right: 0;
      }
    }

    .divider {
      margin: 2em auto;
      height: 0.125em;
      width: 100%;
    }

    .flex-column {
      align-items: center;
      width: 100%;
    }

    .stageline {
      flex-wrap: wrap;
    }

    .current-stage, .other-stages {
      margin-bottom: 2em;
      justify-content: center;
      align-items: center;
    }

    .stage-with-steps {
      width: 100%;
      align-items: center;
      flex-wrap: wrap;
    }

    .title, .sub-title {
      text-align: center;
    }

    .stageline-button {
      float: none;
      margin: 0 auto;
    }
  }
}

@media (max-width: 598px) {
  #stageline-overview {
    .single-stage {
      .cta-image {
        img {
          height: 10em;
          width: 10em;
        }
      }
    }

    .stage-with-steps {
      flex-flow: row wrap;
      margin: 0 auto;
      justify-content: center;
      align-items: center;
    }

    .stageline-button-container {
      width: 100%;

      .stageline-button {
        width: 100%;
      }
    }
  }
}
</style>
