var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "stageline-overview" } },
    [
      _vm.loaded && _vm.currentStage
        ? _c(
            "div",
            {
              staticClass: "stageline",
              class: !_vm.nextIncompleteStages.length ? "single-stage" : "",
            },
            [
              !_vm.allStagesCompleted
                ? _c("div", { staticClass: "d-flex flex-column" }, [
                    _c("div", { staticClass: "current-stage" }, [
                      !_vm.isCtaPeriod
                        ? _c("h3", { staticClass: "title text-center" }, [
                            _vm._v("\n          Current Stage\n        "),
                          ])
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "stage-with-steps" },
                        [
                          _vm.ctaSlideImage?.image_urls.length
                            ? _c("div", { staticClass: "cta-image" }, [
                                _c("img", {
                                  attrs: {
                                    src: _vm.ctaSlideImage.image_urls[0],
                                    alt: _vm.ctaSlideImage.image_alt_text,
                                  },
                                }),
                              ])
                            : _c("stageline-navigation-icon", {
                                attrs: {
                                  type: _vm.currentStage.icon_name,
                                  "icon-status": _vm.iconStatus,
                                  width: 75,
                                  height: 75,
                                },
                              }),
                          _c(
                            "div",
                            { staticClass: "steps-list" },
                            [
                              _c("div", { staticClass: "sub-title" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.isCtaPeriod
                                        ? "Business Needing Review"
                                        : _vm.currentStage.title
                                    ) +
                                    "\n            "
                                ),
                              ]),
                              _vm.isCtaPeriod
                                ? [
                                    _vm._m(0),
                                    _c("div", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.ctaSlideDescription
                                        ),
                                      },
                                    }),
                                  ]
                                : _vm._l(_vm.stepsList, function (step) {
                                    return _c(
                                      "div",
                                      {
                                        key: step.id,
                                        staticClass: "step",
                                        class: {
                                          active: _vm.stepIsCurrent(step),
                                          complete:
                                            _vm.stepIsComplete(step) &&
                                            !_vm.stepIsCurrent(step),
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "indicator" },
                                          [
                                            _vm.stepIsComplete(step) &&
                                            !_vm.stepIsCurrent(step)
                                              ? _c("step-complete-icon")
                                              : _vm.stepIsCurrent(step)
                                              ? _c("step-current-icon")
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(step.title) +
                                            "\n            "
                                        ),
                                      ]
                                    )
                                  }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "mt-auto align-items-end stageline-button-container",
                      },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "next-button stageline-button",
                            attrs: {
                              variant: "primary",
                              "aria-label": "Continue button",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.navigateToStageline()
                              },
                            },
                          },
                          [
                            _vm._v("\n          Continue\n          "),
                            _c("fa-icon", {
                              staticClass: "ml-1",
                              attrs: { icon: "chevron-right" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _c("stageline-end-page", { attrs: { "is-slide": false } }),
              !!_vm.nextIncompleteStages.length
                ? [
                    _c("div", { staticClass: "divider" }),
                    _c("div", { staticClass: "d-flex flex-column" }, [
                      _c(
                        "div",
                        { staticClass: "other-stages" },
                        [
                          _c("h3", { staticClass: "title" }, [
                            _vm._v(
                              "\n            Other Stages of Interest\n          "
                            ),
                          ]),
                          _vm._l(_vm.nextIncompleteStages, function (stage) {
                            return _c(
                              "div",
                              { key: stage.id, staticClass: "stage" },
                              [
                                _c("stageline-navigation-icon", {
                                  staticClass: "stage-icon",
                                  attrs: {
                                    type: stage.icon_name,
                                    "icon-status": _vm.iconStatus,
                                    width: 25,
                                    height: 25,
                                  },
                                }),
                                _c(
                                  "a",
                                  {
                                    staticClass: "stage-name",
                                    attrs: {
                                      href: "#",
                                      "aria-label": stage.title + " link",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.navigateToStageline(stage)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(stage.title) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "mt-auto align-items-end stageline-button-container",
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass:
                                "next-button stageline-button more-stages-button",
                              attrs: {
                                variant: "outline-primary",
                                "aria-label": "See more button",
                              },
                              on: { click: _vm.navigateToDecisionTree },
                            },
                            [
                              _vm._v("\n            See More\n            "),
                              _c("fa-icon", {
                                staticClass: "more-stages-chevron",
                                attrs: { icon: "chevron-right" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm.loaded && !_vm.currentStage
        ? _c(
            "div",
            [
              _c(
                "b-button",
                {
                  staticClass: "next-button",
                  attrs: {
                    variant: "primary",
                    "aria-label": "Start Stageline button",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.navigateToStageline()
                    },
                  },
                },
                [_vm._v("\n      Start Stageline\n    ")]
              ),
            ],
            1
          )
        : _c("ct-centered-spinner", [_vm._v("\n    Loading Stages...\n  ")]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cta-title" }, [
      _c("h2", [
        _vm._v(
          "\n                  Corporate Transparency Act\n                "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }